import { render, staticRenderFns } from "./PerformanceClick.Report.vue?vue&type=template&id=dc784270&scoped=true&"
import script from "./PerformanceClick.Report.vue?vue&type=script&lang=js&"
export * from "./PerformanceClick.Report.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dc784270",
  null
  
)

export default component.exports