<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-bullseye"></i> Performance Click Report</h2>

        <div class="row listing">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <div class="form-inline">
                            <div class="form-group mr-1">
                                <select class="form-control">
                                    <option value="" selected>-- Site Impact --</option>
                                </select>
                            </div>
                            <div class="form-group mr-1">
                                <select v-model="temp.client" class="form-control">
                                    <option value="" selected>-- Any Client --</option>
                                    <option v-for="client in clients" :key="client" :value="client">{{client}}</option>
                                </select>
                            </div>
                            <div class="form-group mr-1">
                                <v-select
                                    style="width: 200px; background: white"
                                    label="name"
                                    v-model="temp.campaign"
                                    placeholder="-- Search Campaigns --"
                                    :reduce="option => option._id"
                                    :filterable="false"
                                    :options="campaigns"
                                    @search="onSearch"
                                >
                                    <template slot="no-options">
                                        Search Campaigns..
                                    </template>
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            {{ option.name }}
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ option.name }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                            <div class="form-group mr-1">
                                <select v-model="temp.provider" class="form-control">
                                    <option selected value="">--Any Provider--</option>
                                    <option value="didsoft">DidSoft</option>
                                    <option value="crawlera">Crawlera</option>
                                    <option value="flipnode">FlipNode</option>
                                    <option value="homeip">HomeIp</option>
                                    <option value="luminate">Luminate</option>
                                    <option value="proxyrack">Proxyrack</option>
                                    <option value="smartproxy">Smartproxy</option>
                                    <option value="netnut">Netnut</option>
                                    <option value="proxyguys">ProxyGuys</option>
                                    <option value="spider">Spider</option>
                                </select>
                            </div>
                            <div class="form-group mr-1">
                                <select v-model="temp.country" @change="Getstates" class="form-control">
                                    <option value="" selected>-- Any Country --</option>
                                    <option v-for="country in countries" :key="country._id" :value="country.code">{{country.name}}</option>
                                </select>
                            </div>
                            <div class="form-group mr-1" v-if="states">
                                <select v-model="temp.state" @change="Getcities" class="form-control">
                                    <option value="" selected>-- Any State --</option>
                                    <option v-for="state in states" :key="state" :value="state">{{state}}</option>
                                </select>
                            </div>
                            <div class="form-group mr-1" style="width: 200px">
                                <ejs-multiselect id='multiselect' placeholder="Select Your Product" :dataSource='prodData'></ejs-multiselect>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="listing pt-3">
                    <ejs-grid :dataSource="sampleData" :allowSorting="true" :allowGrouping='true'>
                        <e-columns>
                            <e-column field="totalClicks" headerText="Total Clicks"></e-column>
                            <e-column field="matchedClicks" headerText="Matched Clicks"></e-column>
                            <e-column field="matchedRates" headerText="Matched Rates"></e-column>
                            <e-column field="shownRate" headerText="Shown Rate %"></e-column>
                            <e-column field="timeOnSite" headerText="Time on Site"></e-column>
                            <e-column field="bounceRate" headerText="Bounce Rate %"></e-column>
                            <e-column field="bounceRate_ga" headerText="Bounce Rate (GA)"></e-column>
                        </e-columns>
                    </ejs-grid>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import VSelect from 'vue-select'
import Vue from 'vue'
import { GridPlugin,  Sort, Group } from '@syncfusion/ej2-vue-grids';
import { MultiSelectPlugin } from "@syncfusion/ej2-vue-dropdowns"
Vue.use(GridPlugin)
Vue.use(MultiSelectPlugin)

import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
import "../../node_modules/@syncfusion/ej2-vue-dropdowns/styles/material.css";
import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css";

export default {
    name: "PerformanceClick.Report",
    props: ['user'],
    components: {
        VSelect,
    },
    data: function() {
        return {
            temp: { country: '', state: '', client: '', provider: '' },
            countries: [],
            campaigns: [],
            states: '',
            cities: [],
            clients: [],
            sampleData: [
                { totalClicks: 1, matchedClicks: 2, matchedRates: 3, shownRate: 4, timeOnSite: 5, bounceRate: 6, bounceRate_ga: 7},
                { totalClicks: 2, matchedClicks: 3, matchedRates: 4, shownRate: 5, timeOnSite: 6, bounceRate: 7, bounceRate_ga: 8},
                { totalClicks: 3, matchedClicks: 4, matchedRates: 5, shownRate: 6, timeOnSite: 7, bounceRate: 8, bounceRate_ga: 9},
                { totalClicks: 1, matchedClicks: 2, matchedRates: 3, shownRate: 4, timeOnSite: 5, bounceRate: 6, bounceRate_ga: 7},
                { totalClicks: 1, matchedClicks: 2, matchedRates: 3, shownRate: 4, timeOnSite: 5, bounceRate: 6, bounceRate_ga: 7},
                { totalClicks: 1, matchedClicks: 2, matchedRates: 3, shownRate: 4, timeOnSite: 5, bounceRate: 6, bounceRate_ga: 7},
            ],
            prodData: ['ContentCatcher', 'EmailVerifier', 'Content Crawler'],
        }
    },
    provide: {
        grid: [Sort, Group]
    },
    created() {
        this.loadcountries();
        // this.loadstates();
        this.loadClients();
    },
    methods: {
        loadcountries: function(){
            var request = {'apikey': this.$root.apikey,sSearch:''};

            return axios.get(`${this.$root.serverUrl}/admin/countries`, {params:request}).then(function(resp){
                //Store the stats
                this.countries = (resp.data && resp.data.data)? resp.data.data : [];
            }.bind(this));
        },
        loadstates: function(){
            this.states = [];
            var request = {'apikey': this.$root.apikey,filters:{}};

            if(this.temp.country) request.filters.country_iso_code = this.temp.country;

            return axios.get(`${this.$root.serverUrl}/admin/geos/state`, {params:request}).then(function(resp){
                //Store the stats
                this.states = (resp.data && resp.data.data)? resp.data.data : [];
            }.bind(this));
        },
        Getstates: function(){
            this.states = [];
            var request = {'apikey': this.$root.apikey,filters:{}};

            if(this.temp.country) request.filters.country_iso_code = this.temp.country;

            return axios.get(`${this.$root.serverUrl}/admin/geos/state`, {params:request}).then(function(resp){
                //Store the stats
                this.states = (resp.data && resp.data.data)? resp.data.data : [];
            }.bind(this));
        },
        Getcities: function(){
            this.cities = [];
            var request = {'apikey': this.$root.apikey,filters:{}};

            if(this.temp.country) request.filters.country_iso_code = this.temp.country;
            if(this.temp.state) request.filters.subdivision_1_iso_code = this.temp.state;

            return axios.get(`${this.$root.serverUrl}/admin/geos/city`, {params:request}).then(function(resp){
                //Store the stats
                this.cities = (resp.data)? resp.data.data : [];
            }.bind(this));
        },
        loadClients: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/campaigns/clients`, {params:request}).then(function(resp){
                //Store the stats
                this.clients = (resp.data && resp.data.data)? resp.data.data : [];
                this.$root.preloader = false;
            }.bind(this));
        },
        onSearch(search, loading) {
            loading(true);
            this.campaignSearch(loading, search, this);
        },
    }
}
</script>

<style scoped>

</style>
